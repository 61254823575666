import React from "react";

import Navbar from "./Navbar";
const Header = () => {
  return (
    <header className="w-full">
      <Navbar />
    </header>
  );
};

export default Header;
